<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="邀请好友赚书豆"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="Invitation-bg">
            <p class="details" @click="rulesClick">活动规则</p>
            <div class="invitation-top">
                <p class="top-title">邀好友送书豆啦</p>
                <p class="top-xian"></p>
                <p class="top-text">书豆上不封顶 <span>可变现</span></p>
                <p class="top-copy">被邀请的好友需注册后才可获得相应的奖励</p>
                <p class="top-button" @click="button">立即邀请</p>
            </div>
            <div class="reward-bg">
                <p class="reward">邀请奖励</p>
                <div class="reward-list">
                    <template v-for="(item,index) in ruleList.rule">
                        <div class="reward-list-div">
                            <div class="reward-list-bg">
                                <p class="reading"><span>{{item.coin}}</span>书豆</p>
                                <p class="reading-title" v-if="index == 0">第一天</p>
                                <p class="reading-title" v-if="index == 1">第二天</p>
                                <p class="reading-title" v-if="index == 2">第三天</p>
                            </div>
                            <p class="reading-text">好友阅读{{item.read_time}}分钟</p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="process">
                <p class="reward">邀请流程</p>
                <div class="process-list">
                    <p class="process-text">发送邀请链接或二维码</p>
                    <p class="process-text">好友下载并注册APP</p>
                    <p class="process-text">好友注册成功你赚书豆</p>
                </div>
            </div>
            <div class="skills">
                <p class="reward">邀请小技巧</p>
            </div>
        </div>
        <!--        分享面板-->
        <van-overlay :show="showShare" @click="showShare = false">
            <Search></Search>
        </van-overlay>
    </div>
</template>

<script>
    import Search from "../../components/Search";

    let wx = require('weixin-js-sdk');
    export default {
        components: {
            Search
        },
        name: "Invitation",
        data() {
            return {
                showShare: false,
                user_id: this.$route.query.user_id,
                ruleList: [],
            }
        },
        created() {
            this.rule()
            this.jssdk()
        },
        methods: {
            jssdk() {
                this.$axios.post('/api/wechat/jssdk', {
                    url: document.location.href,
                })
                    .then(res => {
                        var res = res.data.data
                        res.share.link = res.share.link + this.user_id
                        wx.config(res.jssdk);
                        wx.checkJsApi({
                            jsApiList: res.jssdk.jsApiList, // 需要检测的JS接口列表，所有JS接口列表见附录2,
                            success: function (res) {
                                // 以键值对的形式返回，可用的api值true，不可用为false
                                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                            }
                        });
                        wx.ready(() => {   //需在用户可能点击分享按钮前就先调用
                            wx.updateAppMessageShareData({
                                title: res.share.sharetitle, // 分享标题
                                desc: res.share.sharedescribe, // 分享描述
                                link: res.share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: res.share.sharepic, // 分享图标
                                success: () => {
                                    // 设置成功
                                }
                            })
                        });
                        wx.ready(() => {      //需在用户可能点击分享按钮前就先调用
                            wx.updateTimelineShareData({
                                title: res.share.sharetitle, // 分享标题
                                link: res.share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: res.share.sharepic, // 分享图标
                                success: () => {
                                    // 设置成功
                                }
                            })
                        });
                    })
                    .catch(err => {
                        this.$toast('获取失败')
                    })
            },
            rule() {
                this.$axios.get('/invite/rule', {
                    params: {
                        user_id: this.user_id,
                    }
                })
                    .then(res => {
                        this.ruleList = res.data.data
                    })
            },
            rulesClick() {
                this.$router.push({
                    path: '/rules'
                })
            },
            button() {
                this.showShare = true
            },
        }
    }
</script>

<style scoped>
    .details {
        position: fixed;
        top: 220px;
        right: 0;
        font-size: 26px;
        color: #fff;
        background: rgba(0, 0, 0, .2);
        border-radius: 40px 0 0 40px;
        padding: 20px 25px;
        z-index: 1;
    }

    .skills {
        background: url("../../assets/images/invitational_tips_bg.png") no-repeat;
        background-size: cover;
        height: 353px;
        position: relative;
        margin-top: 40px;
        margin-bottom: 100px;
    }

    .process-text {
        color: #333333;
        font-size: 28px;
        text-align: center;
        padding: 0 15px;
    }

    .process-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 260px;
        margin-bottom: 20px;
    }

    .process {
        background: url("../../assets/images/invitation_process.png") no-repeat;
        background-size: cover;
        position: relative;
        padding: 40px;
        margin-top: 40px;
    }

    .reward-list-div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .reading-text {
        font-size: 26px;
        color: #F5325E;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .reading-title {
        font-size: 24px;
        color: #FF4364;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
    }

    .reading > span {
        font-size: 24px;
        font-weight: bold;
    }

    .reading {
        position: absolute;
        color: #FF4364;
        font-size: 20px;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        text-align: center;
    }

    .reward-list-bg {
        background: url("../../assets/images/Invitation_reward_day.png") no-repeat;
        background-size: cover;
        height: 138px;
        width: 154px;
        position: relative;
    }

    .reward-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 100px;
    }

    .reward {
        color: #fff;
        font-size: 36px;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    .reward-bg {
        background: url("../../assets/images/invitation_reward_bg.png") no-repeat;
        background-size: cover;
        padding: 40px;
        margin: 40px 0 0 0;
        position: relative;
    }

    .top-button {
        background: url("../../assets/images/invite_button.png") no-repeat;
        background-size: cover;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 34px;
    }

    .top-copy {
        border-top: 1px solid rgba(255, 207, 193, .4);
        color: #999;
        font-size: 20px;
        text-align: center;
        padding: 20px;
        margin-top: 40px;
    }

    .top-xian {
        width: 382px;
        height: 1px;
        background: rgba(255, 207, 193, 1);
        opacity: 0.6;
        margin: 40px auto;
    }

    .top-text {
        text-align: center;
        color: #F5325E;
        font-size: 71px;
        font-weight: bold;
        position: relative;
    }

    .top-text > span {
        position: absolute;
        right: 40px;
        top: -40px;
        width: 76px;
        height: 31px;
        background: rgba(255, 191, 57, 1);
        border-radius: 18px 15px 15px 0px;
        font-size: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .top-title {
        font-size: 66px;
        color: #F5325E;
        text-align: center;
        font-weight: bold;
    }

    .invitation-top {
        background: url("../../assets/images/invite1_bg.png") no-repeat;
        background-size: cover;
        margin-top: 200px;
        padding: 65px 40px 40px;
    }

    .Invitation-bg {
        background: url("../../assets/images/invite_bg.png") no-repeat;
        background-size: cover;
        padding: 40px 20px;
    }
</style>